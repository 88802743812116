import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const documentale = {
    state: {
        // eslint-disable-next-line
        access_token: configDocumentale.access_token,
        // eslint-disable-next-line
        login_url: configDocumentale.login_url,
        // eslint-disable-next-line
        service_url: configDocumentale.service_url,
        decoded_access_token: null
    },
    mutations: {
        setAccessToken(state, access_token) {
            state.access_token = access_token;
            state.decoded_access_token = Vue.prototype.jwt.decode(access_token);
        },
        setLoginUrl(state, login_url) {
            state.login_url = login_url;
        },
        setServiceUrl(state, service_url) {
            state.service_url = service_url;
        }
    },
    getters: {
        access_token: state => state.access_token,
        login_url: state => state.login_url,
        service_url: state => state.service_url,
        decoded_access_token: state => state.decoded_access_token
    }
};
const fatture = {
    namespaced: true,
    state: {
        fattureMapping: {
            CondizioniPagamento: {
                TP01: 'pagamento a rate',
                TP02: 'pagamento completo',
                TP03: 'anticipo'
            },
            TipoDocumento: [
                {code: 'TD01', value: 'fattura'},
                {code: 'TD02', value: 'acconto/anticipo su fattura'},
                {code: 'TD03', value: 'acconto/anticipo su parcella'},
                {code: 'TD04', value: 'nota di credito'},
                {code: 'TD05', value: 'nota di debito'},
                {code: 'TD06', value: 'parcella'},
                {code: 'TD07', value: 'fattura semplificata'},
                {code: 'TD08', value: 'nota di credito semplificata'},
                {code: 'TD09', value: 'nota di debito semplificata'},
                {code: 'TD20', value: 'autofattura'},
                {code: 'TD16', value: "integrazione fattura reverse charge interno"},
                {code: 'TD17', value: "integrazione/autofattura per acquisto servizi dall'estero"},
                {code: 'TD18', value: "integrazione per acquisto di beni intracomunitari"},
                {code: 'TD19', value: "integrazione/autofattura per acquisto di beni ex art.17 c.2 DPR 633/72"},
                {code: 'TD21', value: "autofattura per splafonamento"},
                {code: 'TD22', value: "estrazione beni da Deposito IVA"},
                {code: 'TD23', value: "estrazione beni da Deposito IVA con versamento dell'IVA"},
                {code: 'TD24', value: "fattura differita di cui all'art. 21, comma 4, lett. a)"},
                {code: 'TD25', value: "fattura differita di cui all'art. 21, comma 4, terzo periodo lett. b)"},
                {code: 'TD26', value: "cessione di beni ammortizzabili e per passaggi interni (ex art.36 DPR 633/72)"},
                {code: 'TD27', value: "fattura per autoconsumo o per cessioni gratuite senza rivalsa"}
            ],
            ModalitaPagamento: [
                {code: 'MP01', value: 'contanti'},
                {code: 'MP02', value: 'assegno'},
                {code: 'MP03', value: 'assegno circolare'},
                {code: 'MP04', value: 'contanti presso Tesoreria'},
                {code: 'MP05', value: 'bonifico'},
                {code: 'MP06', value: 'vaglia cambiario'},
                {code: 'MP07', value: 'bollettino bancario'},
                {code: 'MP08', value: 'carta di pagamento'},
                {code: 'MP09', value: 'RID'},
                {code: 'MP10', value: 'RID utenze'},
                {code: 'MP11', value: 'RID veloce'},
                {code: 'MP12', value: 'RIBA'},
                {code: 'MP13', value: 'MAV'},
                {code: 'MP14', value: 'quietanza erario'},
                {code: 'MP15', value: 'giroconto su conti di contabilità speciale'},
                {code: 'MP16', value: 'domiciliazione bancaria'},
                {code: 'MP17', value: 'domiciliazione postale'},
                {code: 'MP18', value: 'bollettino di c/c postale'},
                {code: 'MP19', value: 'SEPA Direct Debit'},
                {code: 'MP20', value: 'SEPA Direct Debit CORE'},
                {code: 'MP21', value: 'SEPA Direct Debit B2B'},
                {code: 'MP22', value: 'Trattenuta su somme già riscosse'},
                {code: 'MP23', value: 'PagoPA'}
            ]
        },
        soggettoDaVisualizzare: '',
        tipologiaDaVisualizzare: '',
        lastResponse: null,
        showedInvoices: [],
        selectedInvoices: [],
        params: {
            tipologia: null,
            anno: null,
            page: null,
            limit: null,
            orderBy: null,
            order: null
        },
        showInvoiceFilters: false,
        invoiceOptionsIndex: null,
    },
    mutations: {
        setSelectedInvoices(state, selectedInvoices) {
            state.selectedInvoices = selectedInvoices;
        },
        setLastResponse(state, response) {
            state.lastResponse = response;
            if (state.params.tipologia === 'passive') {
                state.soggettoDaVisualizzare = 'CedentePrestatore';
                state.tipologiaDaVisualizzare = 'passive';
            } else {
                state.soggettoDaVisualizzare = 'CessionarioCommittente';
                state.tipologiaDaVisualizzare = 'attive';
            }
            state.showedInvoices = response.data.items;
        },
        setParams(state, params) {
            state.params = params;
        },
        setOrderBy(state, param) {
            let order = state.params.order;
            if (state.params.orderBy === param) {
                order = (order === 'ASC') ? 'DESC' : 'ASC';
            } else {
                order = 'DESC';
            }
            state.params = Object.assign({}, state.params, {
                orderBy: param,
                order: order
            });
        },
        showInvoiceOptions(state, showedInvoiceIndex) {
            state.invoiceOptionsIndex = showedInvoiceIndex;
        },
        hideInvoiceOptions(state) {
            state.invoiceOptionsIndex = null;
        },
        updateInvoice(state, invoice) {
            state.showedInvoices[state.showInvoiceOptions] = invoice;
        },
        showInvoiceFilters(state) {
            state.showInvoiceFilters = !state.showInvoiceFilters;
        },
        updateShowedInvoice(state, invoice) {
            state.showedInvoices[state.showInvoiceOptions] = Object.assign({}, invoice);
        }
    },
    getters: {
        TipiDocumento: state => state.fattureMapping.TipoDocumento,
        getTipoDocumento: (state) => (code) => {
            return state.fattureMapping.TipoDocumento.find(tipoDocumento => tipoDocumento.code === code)
        },
        ModalitaPagamento: state => state.fattureMapping.ModalitaPagamento,
        getModalitaPagamento: (state) => (code) => {
            return state.fattureMapping.ModalitaPagamento.find(ModalitaPagamento => ModalitaPagamento.code === code)
        },
    }
};
export default new Vuex.Store({
    modules: {
        documentale: documentale,
        fatture: fatture,
    }
})
