import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
    mode: 'history',
    // eslint-disable-next-line
    base: configDocumentale.base_path,
    routes: [
        {
            path: '/',
            redirect: '/fatture',
            name: 'home',
            component: Home
        },
        {
            path: '/fatture/:tipologia?/:anno?/:id?',
            name: 'fatture',
            component: () => import('./views/Fatture.vue')
        }
    ]
})
