import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import '@babel/polyfill'


Vue.config.productionTip = false

import qs from 'qs';

Vue.prototype.qs = qs;

import Axios from 'axios'

Axios.defaults.paramsSerializer = params => {
    return qs.stringify(params, {arrayFormat: 'indices'});
};
Vue.prototype.$http = Axios;

import Moment from 'moment';

Moment.locale('it');
Vue.prototype.moment = Moment;

import jsonwebtoken from 'jsonwebtoken';

Vue.prototype.jwt = jsonwebtoken;

import fileSaver from 'file-saver';

Vue.prototype.fileSaver = fileSaver;

import _ from 'lodash';

Vue.prototype._ = _;

import dragselect from 'dragselect';

Vue.prototype.dragselect = dragselect;

import flatPickr from 'vue-flatpickr-component';

Vue.component('flatPickr', flatPickr);
import 'flatpickr/dist/flatpickr.css';
import "flatpickr/dist/themes/material_green.css"

import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside);


import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faCheckSquare,
    faSearch,
    faCoffee,
    faFile,
    faFileInvoice,
    faFolder,
    faChevronLeft,
    faChevronRight,
    faForward,
    faFastForward,
    faBackward,
    faFastBackward,
    faSpinner,
    faSlidersH,
    faTimes,
    faCloudDownloadAlt,
    faPaperclip,
    faEye,
    faFilePdf,
    faFileExcel,
    faAngleDown,
    faAngleUp,
    faExclamationCircle,
    faEdit,
    faTrashAlt,
    faPlus
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome';

library.add(faCheckSquare,faPlus,faTrashAlt,faFileExcel, faSearch, faEdit, faAngleDown, faAngleUp, faFilePdf, faCoffee, faFile, faFolder, faChevronRight, faForward, faFastForward, faBackward, faFastBackward, faSpinner, faSlidersH, faTimes, faChevronLeft, faCloudDownloadAlt, faPaperclip, faEye, faExclamationCircle, faFileInvoice);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
require("typeface-open-sans");
require("typeface-roboto-slab");

import VTooltip from 'v-tooltip'

Vue.use(VTooltip);
Vue.directive('floatOnly', {
    bind(el) {
        el.addEventListener('keyup', () => {
            let regex = /^[1-9]{1}[0-9]*\.?[0-9]{0,2}$/;
            if (!regex.test(el.value)) {
                el.value = el.value.slice(0, -1)
            }
        })
    }
})
new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
